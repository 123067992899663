import { useContext } from "react";
import { useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import AuthContext from "../../context/AuthContext";
import { useScrollPosition } from "../../hooks/useScrollPosition";


function CreateNav() {
	const [auth, setAuth] = useContext(AuthContext);

	const navigate = useNavigate();

	// function logout() {
	// 	setAuth(null);
	// 	navigate("/");
	// }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const scrollPosition = useScrollPosition()

	return (
		<Navbar collapseOnSelect expand="lg" sticky="top" className={classNames (scrollPosition > 0 ? 'nav__sticky' : 'nav__bar')}>
            <Container fluid className="navbar__wrapper">
                <Navbar.Brand href="/" className="logo"><h1>Kikki Aikio</h1></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="d-inline d-lg-none" id="collasible-nav-dropdown" />
                <Navbar.Collapse title="Dropdown" id="collasible-nav-dropdown" menuvariant="dark">
                    {/* <NavDropdown title="Menu" id="collasible-nav-dropdown"> */}
                        <Nav className="mr-auto">
                            <NavLink to="/" className="nav-link">Home</NavLink>
					        <NavLink to="/bio" className="nav-link">Bio</NavLink>
					        <NavLink to="/musikk" className="nav-link">Music</NavLink>
					        {/* <NavLink to="/kontakt" className="nav-link">Contact</NavLink> */}
                        </Nav>
                    {/* </NavDropdown> */}
                </Navbar.Collapse>
                {/* </Navbar.Toggle> */}
            </Container>
        </Navbar>
	);
}

export default CreateNav;
