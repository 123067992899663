import Container from "react-bootstrap/Container";
import { usePageTitle } from "../../common/PageTitle";
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom";
import H2Title from "../../layout/H2Title";
import H3Title from "../../layout/H2Title";
import CtaButton from "../../common/CtaButton";
import BioButton from "../../common/BioButton";
import img from "../../../images/kikki-young-small.jpg"



export default function HomePage() {
	const [page_title, setPageTitle] = usePageTitle("Kikki Aikio homepage | Sami musician");
	return (
		<main className="wrapper">
			<Container>
				<hr className="top__divider"/>
				<section className="text__content">
				<div className="">
					<p className="mini__heading" >New Release</p>
						<H2Title title="I Herrens namn" />
						<p>October 25th, 2024: Kikki Aikio is excited to announce the release of her latest album, "I Herrens namn".</p>
						<p>The new album features 14 tracks, drawing inspiration from her Swedish heritage through her mother's side.</p>
						<p>The lyrics of "I Herrens namn" consists of texts from the Old Testament. Several songs on the album have been previously released in English, Sámi, Norwegian, Italian, Spanish, and French, highlighting the album's international scope and Kikki's diverse artistic vision.</p>
						<p>Kikki would like to extend a special thanks to Laila Jernsletten for sponsoring this release, and she expresses her gratitude to God for completing what He starts.</p>
						<iframe 
						className="home_iframe"
						src="https://open.spotify.com/embed/album/2mXpY6nNCtCxgBO4B2yl9Y?utm_source=generator&theme=0" 
						width="100%" 
						height="152" 
						allowfullscreen="" 
						allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"
						title="I Herrens namn">
						</iframe>
					</div>
				</section>
				<CtaButton cta="Go to album"/>
				{/* <hr /> */}
				<section className="hero__container">
					<img className="hero__image" src={img} alt="Kikkio Aikio profile image"/>
				</section>
				<section className="text__content">
				<div className="">
					<p className="mini__heading"></p>
						<H2Title title="La Plus Grande" />
						<p>October 18th, 2024 marked the release of Kikki Aikio's album "La Plus Grande".
						</p>
						<p>Kikki collaborated with Simon H. Jernsletten to adapt the songs into French. Simon is pursuing a PhD in Social and Human Sciences in Colombia, where his research focuses on human rights defenders in the state of Cauca.</p>
						<p>"Thanks to Simon for kind corrections concerning the language. Thanks to Jusse Aspenes Jernsletten for cover design. Thank you Laila Jernsletten for sponsoring this release. And thanks be to God for finishing what He initiates."</p>
						<div className="cite__wrapper">
						</div>
						<iframe
						className="home_iframe"
						src="https://open.spotify.com/embed/album/5UJrBewVIWSW4x9taz3T3G?utm_source=generator&theme=0" 
						width="100%" 
						height="152"
						allowfullscreen=""
						allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
						title="La Plus Grande"
						loading="lazy">
						</iframe>
					</div>
				</section>
				<CtaButton cta="See full discography"/>
				<section className="text__content">
					<div className="quote__wrapper">
						<q className="quote">For all of you are children of the light and children of the day. We are not of the night or of darkness.</q>
						<br/>
						<div className="cite__wrapper">
						<cite className="cite">- Thessalonians 5:5</cite>
						</div>
					</div>
					<hr />
				</section>
				<section className="text__content">
					<div className="">
						<H2Title title="20 years anniversary" />
						<p>In 2023, the Christian Sámi singer songwriter Kikki Aikio celebrated 20 years as a recording artist with numerous albums in Sámi, English and Norwegian.</p>
					</div>
					<BioButton link="/bio" cta="Biography"/>
				</section>
			</Container>
		</main>
	);
}
