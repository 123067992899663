import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faApple, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Album({title, release, type, language, image, alt, spotify, apple, youtube, wavlake, fountain}) {
    return (
        <>
            <Card className="card" style={{ width: '20rem' }}>
                <Card.Img className='album__image' variant="top" src={image} alt={alt} />
                <Card.Body>
                    <h3 className='card__title'>{title}</h3>
                    <div className="album_info">
                        <p className='album_info-item'>{release}</p>
                        <p className='album_info-item'>|</p>
                        <p className='album_info-item'>{type}</p>
                    </div>
                    <p className='record'>Language: {language}</p>
                    <p className='record'>Label: Nival Records</p>
                    <p className='album__para'>Listen on</p>
                    <div className='wrapper__listen'>
                        <div className='wrapper__icon-light'>
                            <Card.Link href={apple} target="_blank">
                                <FontAwesomeIcon icon={faApple} size="xl" />
                            </Card.Link>
                            <Card.Link href={spotify} target="_blank">
                                <FontAwesomeIcon icon={faSpotify} size="xl" />
                            </Card.Link>
                            <Card.Link href={youtube} target="_blank">
                                <FontAwesomeIcon icon={faYoutube} size="xl" />
                            </Card.Link>
                        </div>
                        <div className='wrapper_listen_textlinks'>
                            <ul>
                            <li className='listen_list' href={wavlake}></li>
                            <li className='listen_list' href={fountain}></li>
                            </ul>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default Album;