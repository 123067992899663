import Container from "react-bootstrap/Container";
// import Col from 'react-bootstrap/Col';
import Heading from "../../layout/Heading";
import Row from 'react-bootstrap/Row';
import { usePageTitle } from "../../common/PageTitle"
import Album from "./AlbumCard";
import hearra from "../../../images/hearra-album.png";
import binna from "../../../images/binna-album.jpg";
import desert from "../../../images/In-The-Desert-album.png";
import traer from "../../../images/traer-album.jpeg";
import illu from "../../../images/illu-album.jpg";
import rohkos from "../../../images/rohkos-album.png";
import rohkos2 from "../../../images/rohkos2-album.jpg";
import beaivi from "../../../images/Dat-beaivi-album.png";
import eva from "../../../images/Eva-album.jpg";
import dakkar from "../../../images/dakkar-album.jpg";
import vaimmo from "../../../images/Vaimmocatnasat.jpg";
import cold from "../../../images/my-cold-heart.jpg";
import stuorimus from "../../../images/stuorimus.jpeg";
import take from "../../../images/take-pride-album.jpg";
import størst from "../../../images/størst-album.png";
import nameOfTheLord from "../../../images/cd11.png";
import italy from "../../../images/italian.jpg";
import greatest from "../../../images/thegreatest.png";
import senor from "../../../images/senor-cover.jpg";
import laplus from "../../../images/laplusgrande-album.png";
import svensk from "../../../images/herrens-namn_cover copy.png"


export default function HomePage() {
	const [page_title, setPageTitle] = usePageTitle("Discography | Kikki Aikio");
	return (
        <main className="wrapper">
            <Container>
                <Heading title="Discography" />
                <div className="album__wrapper">
                    <div>
                        <Album image={svensk} alt="Cover graphic for Kikki Aikios album I Herrens namn" title="I Herrens namn" release="2024" type="ALBUM" language="Svensk" spotify="https://open.spotify.com/album/2mXpY6nNCtCxgBO4B2yl9Y?si=b7VITmisT9qc8peP1wSMSA" apple="https://music.apple.com/no/album/i-herrens-namn/1775693645" youtube="https://youtube.com/playlist?list=OLAK5uy_mi74lXDbqjjv9CmZxqezQjMK9CBn50d30&si=OsEqAplmMe6IbMIy" wavlake=""/>
                    </div>
                    <div>
                        <Album image={laplus} alt="Cover graphic for Kikki Aikios album La Plus Grande" title="La Plus Grande" release="2024" type="ALBUM" language="French" spotify="https://open.spotify.com/album/5UJrBewVIWSW4x9taz3T3G?si=CUbmg8PiQjGqYR8EZJrcPA" apple="https://music.apple.com/no/album/la-plus-grande/1773745570" youtube="https://youtube.com/playlist?list=OLAK5uy_lqLH8wevsx2sxBr8srbaVAM3CgpaWZjds&si=tvTCsOvc_DyN9z-Y" wavlake="https://wavlake.com/kikki-aikio-espa-ol-"/>
                    </div>
                    <div>
                        <Album image={senor} alt="Cover graphic for Kikki Aikios album En el nombre del SEÑOR" title="En el nombre del SEÑOR" release="2024" type="ALBUM" language="Spanish" spotify="https://open.spotify.com/album/2SBpUP2cEuIJIVdlvVTiL4?si=OrONij_4RDugX3JvCWiZ-Q" apple="https://music.apple.com/no/album/en-el-nombre-del-se%C3%B1or/1752638900" youtube="https://www.youtube.com/playlist?list=OLAK5uy_nNVKsJwPJ_NyaeAR6QG64sqT9e6mps6ME" wavlake="https://wavlake.com/kikki-aikio-espa-ol-"/>
                    </div>
                    <div>
                        <Album image={greatest} alt="Cover graphic for Kikki Aikios album The Greatest" title="The Greatest" release="2024" type="ALBUM" language="English" spotify="https://open.spotify.com/album/2lmrXMMHoiGkVwRxoAmOxv?si=iOAzbFBBQpS6h0IZiAlHDw" apple="https://music.apple.com/no/album/the-greatest/1745665303" youtube="https://youtube.com/playlist?list=OLAK5uy_nekTqoHFbfgkD6ifJTiUNCDg-oLoNwWt8&si=2wiFV5uvNbtlBCOV"/>
                    </div>
                    <div>
                        <Album image={italy} alt="Cover graphic for Kikki Aikios album Nel nome di Dio" title="Nel nome di Dio" release="2024" type="ALBUM" language="Italian" spotify="https://open.spotify.com/album/44fAHvWW8oJOrTuhsmQiHx?si=BXfQA2i2TTu13BEooJ1zLQ" apple="https://music.apple.com/no/album/nel-nome-di-dio/1732433173" youtube="https://youtu.be/xe4XHzRJz64?si=xFui2a-cTUG60y0M"/>
                    </div>
                    <div>
                        <Album image={nameOfTheLord} alt="Cover graphic for Kikki Aikios album In The Name Of The Lord" title="In The Name Of The Lord" release="2024" type="ALBUM" language="English" spotify="https://open.spotify.com/album/1IVBwVWiA0FcYHZibcHkaz?si=nFxEt52AR-CTS_LejbaqZA" apple="https://music.apple.com/no/album/in-the-name-of-the-lord/1725979356" youtube="https://youtube.com/playlist?list=OLAK5uy_kB-Wyxc7kZAQPWrns8srFd_0CXGlgiM78&si=lUYEQwGCDxJE704d" />
                    </div>
                    <div>
                        <Album image={størst} alt="Cover graphic for Kikki Aikios album Størst" title="Størst" release="2023" type="ALBUM" language="Norwegian" spotify="https://open.spotify.com/album/1YrNwTUrisakZZudpO4s9w?si=R7QEXsJSTpayQOngPgSGgQ" apple="https://music.apple.com/no/album/st%C3%B8rst/1712097976" youtube="https://youtube.com/playlist?list=OLAK5uy_lEWTtrAFIFEKPRayIB29tvN8k7Mx6a87M&si=ytcd4wMPCyUxBykG" />
                    </div>
                    <div>
                        <Album image={stuorimus} alt="Cover graphic for Kikki Aikios album Stuorimus" title="Stuorimus" release="2023" type="ALBUM" language="Sami" spotify="https://open.spotify.com/album/50lzeFWMLQ4A8XlBuJnMVg?si=mXa5aqbNSzKge6QApONZ-g" apple="https://music.apple.com/no/album/stuorimus/1674575044" youtube="https://youtube.com/playlist?list=OLAK5uy_na-_SIKg-bxgGfmzq-Wt7eMQi7hIY5_E0&si=ehuWbtM96mJ-n-bO" />
                    </div>
                    <div>
                        <Album image={hearra} alt="Cover graphic for Kikki Aikios album Hearrá Vehkin" title="Hearrá Vehkin" release="2022" type="ALBUM" language="Sami" spotify="https://open.spotify.com/album/4cL1RFOpTrDP35gmaiRraw?si=9fn97be1TgSiE7EqT9hnaA" apple="https://music.apple.com/no/album/hearr%C3%A1-vehkiin/1642358164" youtube="https://youtube.com/playlist?list=OLAK5uy_ksKudBiL75bJn4XEhAL3k9IrfON0NE5eI&si=M0rJYhBAsI7obzDb" />
                    </div>
                    <div>
                        <Album image={desert} alt="Cover graphic for Kikki Aikios album In the Desert" title="In the desert" release="2021" type="ALBUM" language="English" spotify="https://open.spotify.com/album/28MuhxG4f2s7rgnS0t7bhc?si=-cVkOZYQSgOFtVLaS1lF2g" apple="https://music.apple.com/no/album/in-the-desert/1567249672" youtube="https://youtube.com/playlist?list=OLAK5uy_lYMjURlX8Nr6je54ZWK-0MLMmsMbR8gt0&si=6HhvOL02eG-8D05j" />
                    </div>
                    <div>
                        <Album image={traer} alt="Cover graphic for Kikki Aikios album Trær" title="Trær" release="2020" type="ALBUM" language="Norwegian" spotify="https://open.spotify.com/album/7viM5wk9UiniEnLaOq1SFt?si=qDowMqE0QqKfalqgFfqixg" apple="https://music.apple.com/no/album/tr%C3%A6r/1516624731" youtube="https://youtube.com/playlist?list=OLAK5uy_ncohAIRnfD8On2DPvLAgqHluNHD_8kyYA&si=0RedeuyVfGz1bOWt" />
                    </div>
                    <div>
                        <Album image={illu} alt="Cover graphic for Kikki Aikios single Illu" title="Illu" release="2019" type="SINGLE" language="Sami" spotify="https://open.spotify.com/album/4s9AbIXsr9WByDFeAuCqET?si=7-kpRfMNTI-mstXNaG_iqA" apple="https://music.apple.com/no/album/illu-single/1488642461" youtube="https://youtube.com/playlist?list=OLAK5uy_m98RA-ChLRh65AFaX7p6-uNC_I3uNDuYo&si=uc7qBGrlBZx69Qic" />
                    </div>
                    <div>
                        <Album image={rohkos2} alt="Cover graphic for Kikki Aikios single Rohkos II" title="Rohkos II" release="2019" type="SINGLE" language="Sami" spotify="https://open.spotify.com/album/0npb5vLYwWpaqVfZwducJ3?si=JTsSh_eAQ5G6WS-C9ynvXg" apple="https://music.apple.com/no/album/rohkos-ii-single/1477516023" youtube="https://youtube.com/playlist?list=OLAK5uy_nRdVbNELkfwE4hVNJcfvVryERYiUgUXR0&si=-PKoz8iD8jSTH85C" />
                    </div>
                    <div>
                        <Album image={beaivi} alt="Cover graphic for Kikki Aikios single Dát beaivi" title="Dát beaivi" release="2019" type="SINGLE" language="Sami" spotify="https://open.spotify.com/album/1PgJo4UAQimXUjjSKqOCZf?si=oa4cnKaxSE6mb7GZnn462g" apple="https://music.apple.com/no/album/d%C3%A1t-beaivi-single/1471336473" youtube="https://youtube.com/playlist?list=OLAK5uy_no8g0iY8fVDUih-gtMOGqVNbkGu4u4qTk&si=ghJa5mMYwPUKmgC1" />
                    </div>
                    <div>
                        <Album image={dakkar} alt="Cover graphic for Kikki Aikios single Dákkár dáhpáhusat" title="Dákkár dáhpáhusat" release="2019" type="SINGLE" language="Sami" spotify="https://open.spotify.com/album/0em0lD3iVnoMwkrSnehZPK?si=97qG1nH1R5GahS-xN_TCeA" apple="https://music.apple.com/no/album/d%C3%A1kk%C3%A1r-d%C3%A1hp%C3%A1husat-mat-o%C4%91%C4%91asit-%C3%A1rpejit-du-v%C3%A1immu-single/1459102704" youtube="https://youtube.com/playlist?list=OLAK5uy_mh1MWHKc6IDgmhmly2uDxOze2g1ejsTTY&si=PbQCOqAIBSMLmc0Y" />
                    </div>
                    <div>
                        <Album image={rohkos} alt="Cover graphic for Kikki Aikios single Rohkos" title="Rohkos" release="2019" type="SINGLE" language="Sami" spotify="https://open.spotify.com/album/1VrLQaTIaWtumeo9krLQoB?si=UIzerl6tS1iihykRI5_5kw" apple="https://music.apple.com/no/album/rohkos-single/1450174849" youtube="https://youtube.com/playlist?list=OLAK5uy_kalgD0c9cz0BjDTUPUxMNmHUSdjGtoTM0&si=I2fs3Ek_noXBOcfe" />
                    </div>
                    <div>
                        <Album image={eva} alt="Cover graphic for Kikki Aikios album Eva čihkkon čivggat" title="Eva čihkkon čivgga" release="2015" type="ALBUM" language="Sami" spotify="https://open.spotify.com/album/3u6JskOhisLfYEs22tRjgi?si=m39Bqzi8RoOc3fBjixWijA" apple="https://music.apple.com/no/album/eva-%C4%8Dihkkon-%C4%8Divggat/990298936" youtube="https://youtube.com/playlist?list=OLAK5uy_kQjwaVOl_LPYa-nj4GVxYWjdsI_ZvZ0Z8&si=wu7ggQHA1QDjCNSS" />
                    </div>
                    <div>
                        <Album image={binna} alt="Cover graphic for Kikki Aikios album Binna Banna" title="Binna Banna" release="2014" type="ALBUM" language="Sami" spotify="https://open.spotify.com/album/0cXmKG0lhJCShWymsM7XO5?si=wjwt3mf9TlimM2xXvmc3_g" apple="https://music.apple.com/no/album/binna-banna/911300738" youtube="https://youtube.com/playlist?list=OLAK5uy_ll8IwCKGT-AOF2SU_i2XPiNkvctW1Bn4c&si=mX9FtZzTpu7pkGUJ" />
                    </div>
                    <div>
                        <Album image={binna} alt="Cover graphic for Kikki Aikios album Binna Banna (Norwegian)" title="Binna Banna (Norwegian)" release="2014" type="ALBUM" language="Norwegian" spotify="https://open.spotify.com/album/3EJVqacGOhjRN2H8DSTUM7?si=9PL_Tf-qTkOsN8AoVVsW6g" apple="https://music.apple.com/no/album/binna-banna/906809407" youtube="https://youtube.com/playlist?list=OLAK5uy_kXqZuYvc3B2AHEadmW4RiCfGE-CpNBUBw&si=m0dlLfXNK5s83u0U" />
                    </div>
                    <div>
                        <Album image={vaimmo} alt="Cover graphic for Kikki Aikios album Váimmočatnasat" title="Váimmočatnasat" release="2013" type="ALBUM" language="Sami" spotify="https://open.spotify.com/album/2GrDLz6HHBFpE4CYRS26Da?si=cQtu7xR5RGCI_UjiTS-QiA" apple="https://music.apple.com/no/album/v%C3%A1immo%C4%8Datnasat/794528259" youtube="https://youtube.com/playlist?list=OLAK5uy_lU1YfVczUqJJO9j0d4kI_ZATieLA8sO5c&si=eMldETM87XH19I3R" />
                    </div>
                    <div>
                        <Album image={cold} alt="Cover graphic for Kikki Aikios album My Cold Heart" title="My Cold Heart" release="2012" type="ALBUM" language="English" spotify="https://open.spot" apple="https://music.apple.com/no/album/take-pride-ep/553225923" youtube="https://youtube.com/playlist?list=OLAK5uy_mvnJ4DLAFLxKf3L1IY9i_pf-tU8DMPivE&si=SKEHy5vGQiI_mmYh" />
                    </div>
                </div>
            </Container>
        </main>
	);
}