import PropTypes from "prop-types";

function Heading({ title }) {
    return <h2 className="heading">{title}</h2>;
}

Heading.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Heading;